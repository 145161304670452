<template>
  <LoaderWrapper
    :loading="getIsLoadingCallDriver"
    text="Идет звонок водителю, пожалуйста, подождите!"
  >
    <ManagerStatistic
      :statistic="timeslotStatistic"
      :enable-excel="isExcelButton"
      :enable-deleting="canMassDelete"
      @addAuto="handleAddAuto"
      @changeManagerTable="handleChangeManagerTable"
      @createExcelReport="handleExcelReport"
      @onMassDelete="handleMassDelete"
    />

    <div v-if="isMobile">
      <manager-table-header-mobile
        :filter-count="filterCount"
        :culture-list="preparedCultureList"
        :exporters-list="preparedExportersList"
        :statuses-list="entriesStatuses"
        :total-count="pagination.totalCount"
        @emitSort="handleSortMobile"
        @addAuto="handleAddAuto"
        @changeManagerTable="handleChangeManagerTable"
        @emitFilterParams="handleFilteredData"
      />
      <manager-list-shipping-mobile
        :total-count="pagination.totalCount"
        :list="preparedTableDataMobile"
        :is-loading="getIsLoading"
        @handleGetMoreTimeslot="changePageMobile"
        @editRow="handleEdit"
        @deleteRow="handleSingleDelete"
      />
      <div class="containerMobile">
        <BottomToolbarButton
          title="Добавить авто"
          @button-clicked="handleAddAuto"
        />
      </div>
    </div>

    <div v-else>
      <ManagerTableHeader
        :culture-list="preparedCultureList"
        :exporter-list="suppliersList"
        :quotas-list="quotasList"
        @emitFilterParams="handleFilteredData"
        @addAuto="handleAddAuto"
        @changeManagerTable="handleChangeManagerTable"
      />

      <ManagerTableBody
        :table-data="preparedTableData"
        :table-fields-desktop="timeslotsTableFields"
        :table-fields-tablet="timeslotsTableFields"
        :table-fields-mobile="timeslotsTableFields"
        :table-fields-small-mobile="timeslotsTableFields"
        :table-dropdown-menu-list="managerTableDropdownMenuList"
        :is-loading="getIsLoading"
        @editRow="handleEdit"
        @deleteRow="handleSingleDelete"
        @selectionChange="handleMassSelection"
        @selectDropdownMenu="handleTableMenu"
        @tableSortByKey="handleSort"
        @forceArrival="handleForceArrival"
        @goToLogs="handleGoToLogs"
      >
        <template #default="slotProps">
          <ManagerTableCell
            :row="slotProps.data.row"
            :prop-key="slotProps.data.prop"
            :status="slotProps.data.row.status"
          />
        </template>
      </ManagerTableBody>

      <TableFooter
        :pagination="pagination"
        text-quantity="Всего автомобилей:"
        @page="changePage"
        @size="changeSize"
      />
    </div>
  </LoaderWrapper>
</template>

<script>
import {
  ADMIN_DELETE_TIMESLOT,
  MANAGER_ADD_TIMESLOT,
  MANAGER_CHANGE_TABLE_COLUMN,
  MANAGER_DELETE_TIMESLOT,
  MANAGER_EDIT_TIMESLOT,
  MANAGER_FORCE_ARRIVAL,
  MANAGER_TIMESLOT_WRONG,
  STEP_MANAGER_EDIT_TIMESLOT,
} from '@/constants/dialogs'
import {
  CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE,
  CHANGE_TIMESLOTS_PAGINATION_PER_PAGE,
  CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS,
  CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS,
  FETCH_MANAGER_EXCEL_REPORT,
  FETCH_TIMESLOTS,
  GET_ACTIVE_TABLE_COLUMN,
  GET_IS_LOADING_CALL_DRIVER,
  GET_IS_LOADING_TIMESLOTS,
  GET_TIMESLOTS_FROM_STATE,
  GET_TIMESLOTS_PAGINATION,
  GET_TIMESLOTS_STATISTIC_FROM_STATE,
  SET_TIMESLOTS_REQUEST_FILTER_PARAMS,
  SET_TIMESLOT_CURRENT_STEP,
} from '@/views/manager/store/actions'
import {
  GET_ARRIVAL_PREFIX,
  GET_IS_INCOMPLETE_PARKING,
  GET_IS_MOBILE,
  GET_LATE_INTERVAL,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
  SET_UPDATE_ACTION,
} from '@/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import { diffTimeFromNowTz, getTime, getTzTime } from '@/core'
import {
  entriesStatuses,
  managerChangeTable,
  managerTableDropdownMenuList,
  timeslotsTableFieldsDesktop,
  timeslotsTableFieldsDesktopStep,
} from '@/views/manager/data/manager'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BottomToolbarButton from '@/UI/form/buttons/BottomToolbarButton'
import LoaderWrapper from '@/UI/loader/LoaderWrapper'
import ManagerListShippingMobile from '@/views/manager/components/mobile/ManagerListShippingMobile'
import ManagerStatistic from '@/views/manager/components/manager-statictic/ManagerStatistic'
import ManagerTableBody from '@/views/manager/components/ManagerTableBody'
import ManagerTableCell from '@/views/manager/components/ManagerTableCell'
import ManagerTableHeader from '@/views/manager/components/ManagerTableHeader'
import ManagerTableHeaderMobile from '@/views/manager/components/mobile/ManagerTableHeaderMobile'
import TableFooter from '@/UI/table/table-footer/TableFooter'

import { DATE_FORMAT, GET_DAY, GET_TIME, WEEKDAY } from '@/constants/date'
import Api from '@/api'

export default {
  name: 'ManagerTable',
  components: {
    ManagerTableHeaderMobile,
    ManagerStatistic,
    LoaderWrapper,
    ManagerTableBody,
    ManagerTableHeader,
    ManagerTableCell,
    TableFooter,
    ManagerListShippingMobile,
    BottomToolbarButton,
  },
  data() {
    return {
      currentFilters: null,
      filterCount: 0,
      expanded: false,
      expanded1: false,
      entriesStatuses,
      preparedCultureList: [],
      preparedExportersList: [],
      quotasList: [],
      managerTableDropdownMenuList,
      currentPage: 1,
      perPage: 10,
      oldSortKey: '',
      order: 0,
      autoFetchTS: null,
      preparedTableDataMobile: [],
      selectedTimeslots: [],
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
      getIsLoading: GET_IS_LOADING_TIMESLOTS,
      getIsLoadingCallDriver: GET_IS_LOADING_CALL_DRIVER,
      tableData: GET_TIMESLOTS_FROM_STATE,
      timeslotStatistic: GET_TIMESLOTS_STATISTIC_FROM_STATE,
      cultureList: GET_CULTURE_FROM_STATE,
      suppliersList: GET_SUPPLIERS_ALL,
      pagination: GET_TIMESLOTS_PAGINATION,
      getTerminalCurrentId: GET_TERMINAL_CURRENT_ID,
      activeTableCol: GET_ACTIVE_TABLE_COLUMN,
      getCurrentSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      isIncompleteParking: GET_IS_INCOMPLETE_PARKING,
      acceptableLateInterval: GET_LATE_INTERVAL,
      earlyArrivedInterval: GET_ARRIVAL_PREFIX,
    }),
    preparedTableData() {
      if (this.tableData) {
        return [...this.tableData]
      }

      return []
    },
    timeslotsTableFields() {
      return this.getCurrentSlug === 'step'
        ? timeslotsTableFieldsDesktopStep.filter(field =>
            this.activeTableCol.includes(field.key),
          )
        : timeslotsTableFieldsDesktop.filter(field =>
            this.activeTableCol.includes(field.key),
          )
    },
    isExcelButton() {
      return Boolean(
        this.currentFilters &&
          this.currentFilters.time_from &&
          this.currentFilters.time_from.length,
      )
    },
    canMassDelete() {
      return Boolean(this.selectedTimeslots.length)
    },
  },
  watch: {
    preparedTableData: {
      immediate: true,
      handler(val) {
        if (this.currentPage === 1) {
          this.preparedTableDataMobile = val

          return
        }
        this.preparedTableDataMobile = [...this.preparedTableDataMobile, ...val]
      },
    },

    cultureList: {
      immediate: true,
      handler(val) {
        this.preparedCultureList = val.map(item => ({
          value: item.id,
          label: `${item.name} ${
            item.harvest_year ? item.harvest_year + ' года' : ''
          } `,
          deleted_at: item.deleted_at,
          harvest_year: item.harvest_year,
          id: item.id,
          name: `${item.name} ${
            item.harvest_year ? item.harvest_year + ' года' : ''
          } `,
          unload_id: item.unload_id,
        }))
      },
    },
    suppliersList: {
      immediate: true,
      handler(val) {
        this.preparedExportersList = val.map(item => ({
          value: item.id,
          label: item.name,
        }))
      },
    },
    // при смене терминала предварительно сбрасываем фильтры
    async getTerminalCurrentId() {
      this.dropTimeslotsRequestFilterParams({})
      await this.fetchTerminalQuotas()
      await this.fetchTimeslots()
    },
    pagination: {
      handler(val) {
        this.currentPage = val.currentPage
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchTerminalQuotas()
    this.setAction(this.fetchTimeslots)
    await this.fetchTimeslots()
    this.autoFetchTS = setInterval(() => this.fetchTimeslots(), 1000 * 60 * 5)
  },
  beforeDestroy() {
    clearInterval(this.autoFetchTS)

    // remove all filter
    this.dropTimeslotsRequestFilterParams({})
  },
  methods: {
    ...mapMutations({
      setAction: SET_UPDATE_ACTION,
      setCurrentStep: SET_TIMESLOT_CURRENT_STEP,
      dropTimeslotsRequestFilterParams: SET_TIMESLOTS_REQUEST_FILTER_PARAMS,
    }),
    ...mapActions({
      fetchTimeslots: FETCH_TIMESLOTS,
      createManagerExcelReport: FETCH_MANAGER_EXCEL_REPORT,
      setTimeslotsRequestFilterParams: CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS,
      setTimeslotsRequestSortParams: CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS,
      setTimeslotsPaginationPerPage: CHANGE_TIMESLOTS_PAGINATION_PER_PAGE,
      setTimeslotsPaginationNewPage: CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE,
    }),
    async fetchTerminalQuotas() {
      try {
        let { data } = await Api.control.quotas.get(this.getTerminalCurrentId)

        this.quotasList = data.map(item => {
          let date = getTime(item.date_from, GET_DAY)
          let day = getTime(item.date_from, WEEKDAY)
          let start = getTzTime(item.date_from, GET_TIME)
          let finishDate =
            getTzTime(item.date_from, DATE_FORMAT) !==
            getTzTime(item.date_to, DATE_FORMAT)
              ? `/ ${getTzTime(item.date_to, GET_DAY)}`
              : ''
          let finishTime = getTzTime(item.date_to, GET_TIME)

          return {
            id: item.id,
            name: `${date}     ${day}     ${start} - ${finishTime}${finishDate}      ${item.exporter_name}    ${item.culture_name}`,
            date,
            day,
            times: `${start} - ${finishTime} ${finishDate}`,
            exporter: item.exporter_name,
            culture: item.culture_name,
          }
        })
      } catch (e) {
        console.log('error loading quotas')
      }
    },
    changePage(page = 1) {
      this.currentPage = page
      this.setTimeslotsPaginationNewPage(page)
    },
    changePageMobile() {
      this.currentPage += 1
      this.setTimeslotsPaginationNewPage(this.currentPage)
    },
    changeSize(perPage = 10) {
      this.setTimeslotsPaginationPerPage(perPage)
    },
    handleFilteredData(filters, count = 0) {
      this.currentFilters = filters
      this.filterCount = count
      for (let key in filters) {
        filters[key]?.length > 0 ? this.filterCount++ : null
      }
      this.preparedTableDataMobile = []

      this.setTimeslotsRequestFilterParams(filters)
    },

    handleEdit(data) {
      this.setDialog({
        name:
          this.getCurrentSlug === 'step'
            ? STEP_MANAGER_EDIT_TIMESLOT
            : MANAGER_EDIT_TIMESLOT,
        visible: true,
        data,
      })
    },
    handleSingleDelete(timeslot) {
      const data = [timeslot.id]

      if (this.isSuperAdmin || this.isAdmin) {
        this.setDialog({ name: ADMIN_DELETE_TIMESLOT, visible: true, data })
      } else {
        this.setDialog({ name: MANAGER_DELETE_TIMESLOT, visible: true, data })
      }
    },
    handleMassDelete() {
      let hasUndeletable = false

      this.selectedTimeslots.forEach(item => {
        if (['arrived', 'late', 'detached'].includes(item.truck_status)) {
          hasUndeletable = true
        }
      })

      if (hasUndeletable) {
        this.$message.error(
          'Нельзя удалить ТС со статусами  “Закончил рейс”, “Опоздал”, “Отказ”, уберите их из списка удаляемых',
        )
      } else {
        const data = this.selectedTimeslots.map(item => item.id)

        if (this.isSuperAdmin || this.isAdmin) {
          this.setDialog({
            name: ADMIN_DELETE_TIMESLOT,
            visible: true,
            data,
          })
        } else {
          this.setDialog({
            name: MANAGER_DELETE_TIMESLOT,
            visible: true,
            data,
          })
        }
      }
    },
    handleMassSelection(timeslots) {
      this.selectedTimeslots = timeslots
    },
    handleTableMenu(key) {
      if (key === managerChangeTable) {
        this.setDialog({
          name: MANAGER_CHANGE_TABLE_COLUMN,
          visible: true,
          data: {},
        })
      }
    },
    handleSort(sortKey) {
      const ASC = ''
      const DESC = '-'
      let currentSortStatus = ''

      if (this.oldSortKey !== sortKey) {
        this.order = 0
        this.oldSortKey = sortKey
      }

      if (this.order !== 2) {
        this.order++
        currentSortStatus =
          this.order === 1 ? `${ASC}${sortKey}` : `${DESC}${sortKey}`
      } else {
        this.order = 0
        currentSortStatus = ''
      }
      this.preparedTableDataMobile = []
      this.setTimeslotsRequestSortParams(currentSortStatus)
    },
    handleSortMobile(sortKey) {
      this.preparedTableDataMobile = []
      this.setTimeslotsRequestSortParams(sortKey)
    },
    handleAddAuto() {
      this.setDialog({
        name: MANAGER_ADD_TIMESLOT,
        visible: true,
        data: {},
      })
    },
    handleChangeManagerTable() {
      this.setDialog({
        name: MANAGER_CHANGE_TABLE_COLUMN,
        visible: true,
        data: {},
      })
    },
    handleForceArrival(data) {
      if (
        // приехал вовремя или опоздал с попаданием в допустимый интервал опоздания
        // или приехал раньше в пределах допустимого интервала прибытия раньше
        (diffTimeFromNowTz(data.window_from) >= 0 &&
          diffTimeFromNowTz(data.window_to) <= 0) ||
        (diffTimeFromNowTz(data.window_to) > 0 &&
          diffTimeFromNowTz(data.window_to) <=
            this.acceptableLateInterval * 3600 * 1000) ||
        (diffTimeFromNowTz(data.window_from) < 0 &&
          Math.abs(diffTimeFromNowTz(data.window_from)) <=
            this.earlyArrivedInterval * 60 * 1000)
      ) {
        this.setDialog({
          name: MANAGER_FORCE_ARRIVAL,
          visible: true,
          data,
        })
      } else if (diffTimeFromNowTz(data.window_from) < 0) {
        // приехал раньше
        if (this.isIncompleteParking) {
          // для терминалов с неполной парковкой нельзя приехать раньше
          this.$alert(
            'Попробуйте подтвердить приезд позднее',
            'Время таймслота еще не наступило',
            {
              confirmButtonText: 'Понятно',
              customClass: 'arrival-box',
              center: true,
              showClose: false,
            },
          )
        } else {
          // для терминалов с обычной парковкой уходим на создание ручного ТС
          this.setDialog({
            name: MANAGER_TIMESLOT_WRONG,
            visible: true,
            data: {
              ...data,
              isEarly: true,
            },
          })
        }
      } else {
        // опоздал
        // eslint-disable-next-line no-lonely-if
        if (this.isIncompleteParking) {
          // для терминалов с неполной парковкой - уже ничего не сделаешь
          this.$alert(
            'Время подтверждения приезда по данному таймслоту вышло.',
            'Невозможно подтвердить приезд',
            {
              confirmButtonText: 'Понятно',
              customClass: 'arrival-box',
              center: true,
              showClose: false,
            },
          )
        } else {
          // для терминалов с обычной парковкой уходим на создание ручного ТС
          this.setDialog({
            name: MANAGER_TIMESLOT_WRONG,
            visible: true,
            data: {
              ...data,
              isEarly: false,
            },
          })
        }
      }
    },
    handleGoToLogs(data) {
      this.setCurrentStep({ step: 2, data })
    },
    handleExcelReport() {
      // с культурой там сложное отображение, завязанное на culture_id, culture_old_id,
      // culture_name, culture_old_name. Для отчета это не подходит, поэтому просто
      // меняем на culture_name
      let fields = this.timeslotsTableFields.map(item => {
        if (item.prop === 'culture') {
          return 'culture_name'
        }

        return item.prop
      })

      this.createManagerExcelReport(fields)
    },
  },
}
</script>

<style lang="sass" scoped>
//это свойство не удалять, оно для культуры из ManagerTableCell
.culture
  width: 230px

.expand-enter-active,
.expand-leave-active
  transition-property: opacity, height

.expand-enter,
.expand-leave-to
  opacity: 0
</style>
